import logistikMain from "../../assets/logistikMain.png";
import akhlak from "../../assets/akhlak.png";
import ViewCount from "../../components/ViewCount.js";
import React, { useEffect, useState } from "react";
import { server } from "../../config/server.js";
export default function Pengaduan() {
  const [data, setData] = useState([]); 
  const [headerImg, setHeaderImg] = useState([]);
  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);
  const getData = () => {
    fetch(`${server}/api/v1/content/list/28`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);
        if (res.status) {
          setData(res.data[0] ? res.data[0] : {});
          setHeaderImg(res.data[0] ? res.data[0].
            content_header_link : '')
        } else {
          throw res
        }



      })


      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
 {/* <div
        class="pageMainImg"
        style={{ backgroundImage: `url(${logistikMain})` }}
      ></div> */}
      <img class="w100" style={{ borderRadius: 20 }} src={headerImg}></img>
      <div class="pageTitle">{data.content_title ? data.content_title : 'Pengaduan'}</div>
      <div class="articleContainer pageText">
      <div className="list image" style={{ textAlign: "justify" }}>
          {data.content_html ? (
            <div
              dangerouslySetInnerHTML={{ __html: data.content_html }}
            ></div>
          ) : null}
      
        </div>
        {/* <div class="list image">
          <p class="MsoNormal">
            <span>
              <span>
                Keluhan Pelanggan merupakan fasilitas yang disediakan kepada
                para pelanggan atau Investor untuk menyampaikan keluhannya
                kepada PT. Kawasan Berikat Nusantara (Persero). Ini merupakan
                hal yang sangat penting bagi kami agar dapat terus berkembang
                dan meningkatkan kualitas pelayanan kami terhadap para investor.
              </span>
            </span>
          </p>
          <p class="MsoNormal">
            <span>
              <span>TATA CARA</span>
            </span>
          </p>
          <p class="MsoNormal">
            <span>
              <span>
                Untuk memudahkan pengaduan, maka akan dijelaskan bagaimana
                prosedur untuk melakukan pengaduan kepada PT. Kawasan Berikat
                Nusantara (Persero) dan bagaimana proses serta mekanisme
                penanganannya.
              </span>
            </span>
          </p>
          <p class="MsoNormal">
            <span>
              <span>
                Proses pengaduan dapat dilakukan melaui 2 cara, diantaranya :
              </span>
              <br />
              <span> 1. Menghubungi kami langsung via Telefon atau Faks.</span>
              <br />
              <span> 2. Mengirimkan e-mail dengan melampirkan&nbsp;</span>
              <a href="http://www.kbn.co.id/assets/file/2017/12/FORMULIR_KELUHAN_PELANGGAN_46.docx">
                <span>Form Keluhan Pelanggan.</span>
              </a>
              <br />
              <span>
                3. Kirimkan langsung Form yang telah terisi ke Bagian Pelayanan
                Kantor Pusat PT. Kawasan Berikat Nusantara (Persero).
              </span>
            </span>
          </p>
          <p class="MsoNormal">
            <span>
              <span>Hubungi Kami :</span>
              <br />
              <span> Keluhan Pelanggan</span>
              <br />
              <span> Telp : (021) 4482-0909 ext 1703</span>
              <br />
              <span>Faks : (021) 4482-0052</span>
              <br />
              <span> Email : pelayanan@kbn.co.id</span>
            </span>
          </p>
          <p class="MsoNormal">
            <b>
              <span>
                <span>5 TAHAP PROSES PENANGANAN KELUHAN PELANGGAN</span>
              </span>
            </b>
          </p>
          <p>
            <span> </span>
          </p>
          <p class="MsoNormal w100 text-center">
            <span></span>
            <img
              class="mw90vw py-4  zoom12"
              src="http://www.kbn.co.id/assets/images/attachment/2019/04/INFOGRAFIS_KELUHAN_PELANGGAN_landscape_120.jpg"
            />
          </p>
          <section id="gallery" class="padding-topx">
            <p></p>
          </section>
        </div> */}

        <ViewCount count={data.views ? data.views : 0} />
      </div>
    </>
  );
}
