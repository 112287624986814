import React, { useEffect, useState } from "react";
import { server } from "../../config/server.js";
import ViewCount from "../../components/ViewCount.js";
import logistikMain from "../../assets/logistikMain.png";

export default function InformasiPemegangSaham() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(`${server}/api/v1/home/downloadFile`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const result = await response.json();
        console.log(result);
        setData(result.data);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
    window.scrollTo(0, 0);
  }, []);

  // const backgroundImage = data.length > 0 ? data[0].content_header_link.replace(/ /g, "%20") : null;
  const contentHTML =
    data.length > 0 && data[0].content_html
      ? data[0].content_html
      : "<p>No Content Available";

  return (
    <>
      {/* <img class="w100" style={{ borderRadius: 20 }} src={backgroundImage}></img> */}
      <div className="pageTitle">
        {data.length > 0 ? "Download File" : "Loading..."}
      </div>
      <div className="articleContainer pageText">
        <div className="list image" style={{ textAlign: "justify" }}>
          {/* <div dangerouslySetInnerHTML={{ __html: `<a>${contentHTML}</a>` }}></div> */}
          <ol>
            {data.map((d, i) => {
              return (
                <a
                  href={d.download_public_link}
                     class="hoverNoColor"
                  target="_blank"
                >
                  <li
                    class="mb-2 px-4 py-2 hoverDark"
                    style={{
                      backgroundColor: "#f5f5f5",
                      borderRadius: 12,
                      transition: "0.2s",
                      cursor: "pointer",
                    }}
                  >
                    <div class="flex justify-content-between">
                      <div> {d.description}</div>
                      <div>
                        <a
                       
                          style={{
                            color: "blue",
                            cursor: "pointer ",
                            textDecoration: "underline",
                          }}
                          target="_blank"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                    <div>{d.penyedia}</div>
                    <div>{d.nilai_pekerjaan}</div>
                  </li>{" "}
                </a>
              );
            })}
          </ol>
        </div>
        <ViewCount count={data.length > 0 ? data[0].views : 0} />
      </div>
    </>
  );
}
