import React, { useEffect, useState } from "react";
import { server } from "../../config/server.js";
import ViewCount from "../../components/ViewCount.js";
import logistikMain from "../../assets/logistikMain.png";

export default function Laporan() {
  const [header, setHeader] = useState({});
  const [album, setAlbum] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [headerResponse, albumResponse] = await Promise.all([
        fetch(`${server}/api/v1/content/list/46`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }),
        fetch(`${server}/api/v1/content/detail/6`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }),
      ]);

      const [headerResult, albumResult] = await Promise.all([
        headerResponse.json(),
        albumResponse.json(),
      ]);

      if (headerResult.status) {
        setHeader(headerResult.data[0] || {});
      } else {
        throw new Error("Failed to fetch header data");
      }

      if (albumResult.status) {
        setAlbum(albumResult.data);
      } else {
        throw new Error("Failed to fetch album data");
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    }
  };

  const backgroundImage = header.content_header_link ? header.content_header_link.replace(/ /g, "%20") : logistikMain;
  const contentTitle = header.content_title || "";
  const contentViews = header.views || "0";

  return (
    <>
      <img className="w100" style={{ borderRadius: 20 }} src={backgroundImage} alt="Background" />
      <div className="pageTitle">{contentTitle}</div>
      <div className="articleContainer pageText">
        <ul className="albumList">
          {album.map((item, index) => (
            <li key={index} className="albumItem">
              <a href={item.content_files[0]?.public_link || "#"} target="_blank" rel="noopener noreferrer">
                {item.content_title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <ViewCount count={contentViews} />
    </>
  );
}
