import whistleblowerMain from "../../assets/whistleblowerMain.png";
import wb1 from "../../assets/wb1.png";
import wb2 from "../../assets/wb2.png";
import wb3 from "../../assets/wb3.png";
import wb4 from "../../assets/wb4.png";
import ViewCount from "../../components/ViewCount.js";
import React, { useEffect, useState } from "react";
import { server } from "../../config/server.js";
import { useNavigate } from "react-router-dom";
import WBSMenu from "./WBSMenu.js";
export default function Whistleblower() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [headerImg, setHeaderImg] = useState([]);
  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);
  const getData = () => {
    fetch(`${server}/api/v1/content/list/27`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);
        if (res.status) {
          console.log(res,'kiu');
          
          setData(res.data[0] ? res.data[0] : {});
          setHeaderImg(res.data[0] ? res.data[0].content_header_link : "");
        } else {
          throw res;
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {/* <div
        class="pageMainImg"
        style={{ backgroundImage: `url(${whistleblowerMain})` }}
      ></div> */}
      <img
        class="w100"
        className="w100 mb-5"
        style={{ borderRadius: 20 }}
        src={headerImg}
      ></img>
      <WBSMenu backButton={false} />
      <div
        class="mw1000 mx-auto flex justify-content-center my-5 fwrap1200"
        style={{
          gap: 54,
        }}
      >
        <div class="" style={{ width: "-webkit-fill-available" }}>
          <div style={{ height: 100 }}>
            <img style={{ maxWidth: 100 }} src={wb1}></img>
          </div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: 24,
              fontWeight: 600,
            }}
            class="mt-3"
          >
            Periksa Syarat Kelengkapan
          </div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
            }}
          >
            Periksa kelengkapan dan syarat pengaduan yang akan Anda buat
          </div>
        </div>
        <div class="" style={{ width: "-webkit-fill-available" }}>
          <div style={{ height: 100 }}>
            <img style={{ maxWidth: 100 }} src={wb2}></img>
          </div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: 24,
              fontWeight: 600,
            }}
            class="mt-3"
          >
            Isi Formulir Pengaduan
          </div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
            }}
          >
            Klik "Buat Pengaduan" lalu isi formulir yang disediakan
          </div>
        </div>
        <div class="" style={{ width: "-webkit-fill-available" }}>
          <div style={{ height: 100 }}>
            <img style={{ maxWidth: 100 }} src={wb3}></img>
          </div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: 24,
              fontWeight: 600,
            }}
            class="mt-3"
          >
            Simpan Info Akun Pelapor
          </div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
            }}
            class="mt-2"
          >
            Simpan dengan baik Info akun untuk masuk ke halaman pelapor
          </div>
        </div>
        <div class="" style={{ width: "-webkit-fill-available" }}>
          <div style={{ height: 100 }}>
            <img style={{ maxWidth: 100 }} src={wb4}></img>
          </div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: 24,
              fontWeight: 600,
            }}
            class="mt-3"
          >
            Pantau Proses Pengaduan
          </div>
          <div
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
            }}
            class="mt-2"
          >
            Pantau seluruh proses pengaduan yang pernah Anda buat sebelumnya
          </div>
        </div>
      </div>

      {/* <div class="articleContainer pageText">
        <p>
          Sistem Whistleblowing PT. Kawasan BerikatNusantara (Persero) adalah
          mekanisme pelaporan perbuatan yang berindikasipelaporan/kecurangan
          atau tindakan korupsi yang terjadi di lingkungan PT.Kawasan Berikat
          Nusantara (Persero) dan merupakan salah satu cara palingefektif untuk
          mencegah dan memerangi praktek yang bertentangan dengan praktekGood
          Corporate Governance (GCG).
        </p>
        <br></br>
        <p>
          Unsur Pengaduan<br></br>
          Pengaduan akan mudah ditindaklanjuti apabila memenuhi beberapa unsur
          berikut :
        </p>

        <div class="flex">
          <div>
            <div class="mx-5">Apa</div>
            <div class="mx-5">Dimana</div>
            <div class="mx-5">Kapan</div>
            <div class="mx-5">Siapa</div>
            <div class="mx-5">Bagaimana</div>
          </div>
          <div>
            <div class="mx-5">
              : Perbuatan berindikasi pelanggaran yang diketahui.
            </div>
            <div class="mx-5">: Dimana perbuatan tersebut dilakukan.</div>
            <div class="mx-5">: Kapan perbuatan tersebut dilakukan.</div>
            <div class="mx-5">
              : Siapa saja yang terlibat dalam perbuatan tersebut.
            </div>
            <div class="mx-5">
              : Bagaimana perbuatan tersebutdilakukan (modus, cara, dsb).
            </div>
          </div>
        </div>
        <br></br>

        <p>
          Pelapor membuat pengaduan/pengungkapan dan mengirimkannya melalui
          sarana/media sebagai berikut :
        </p>

        <div class="flex">
          <div>
            <div class="mx-5">Telp.  </div>
            <div class="mx-5">E-mail  </div>
          </div>
          <div>
            <div class="mx-5">: (021) 4482-0909 ext. 1334</div>
            <div class="mx-5">: pengaduan@kbn.co.id</div>
          </div>
        </div>
        <br></br>
        <p>
          Kotakpengaduan yang diletakan di tempat strategis yaitu dilingkungan
          Kantor PusatPT. KBN (P), Kantor SBU Kawasan Cakung, Kantor SBU
          Pelayanan Logistik, KantorSBU Kawasan Priok dan Marunda.
        </p>
        <p>Perlindungan dan Hak Saksi Pelapor</p>
        <p>
          Sesuai dengan Pasal VII dalam Surat Keputusan Direksi PT.Kawasan
          Berikat Nusantara ( Persero ) No. 119/SKD/DU.DU54/06/2020 tentang
          Perubahan Atau Pedoman Sistem Pelaporan Whistlebowing System (WBS) di
          PT. Kawasan Berikat Nusantara (Persero ) menyebutkan bahwa seorang
          sanksi pelapor berhak :
        </p>
        <p>
          <ul>
            <li>
              Memperoleh perlindungan atas kerahasiaan identitas pribadi,
              jabatannya dan hak hak sebagai sebagai pegawai lainnya yang
              berkenan dengan kesaksiannya yang akan,sedang atau telah
              diberikannya.
            </li>
            <li>Memberikan keterangan tanpa tekanan dari pihak manapun.</li>
            <li>Bebas dari pertanyaan yang menjerat</li>
            <li>
              Mendapat informasi mengenai perkembangan kasus yang telah
              dilaporkan.
            </li>
            <li>
              Memperoleh penghargaan ( reward ) yang akan ditetapkan oleh
              Direksi
            </li>
          </ul>
        </p>
        <ViewCount count={data.views ? data.views : 0} />
      </div> */}
      {localStorage.token ? (
        <>
          <div class="pageTitle">
            {data.content_title ? data.content_title : "Whistleblower"}
          </div>{" "}
          <div class="articleContainer pageText">
            {" "}
            <div>
              <h3>
                <strong>SISTEM PELAPORAN PELANGGARAN</strong>
              </h3>
              <h2>
                <strong>
                  <i>Whistle Blowing System</i>
                </strong>
              </h2>

              <p>
                <i>Whistle Blowing System</i> (WBS) adalah mekanisme pelaporan
                perbuatan yang berindikasi pelaporan/ kecurangan atau tindakan
                korupsi yang terjadi di Lingkungan PT KBN dan merupakan salah
                satu cara paling efektif untuk mencegah dan memerangi praktik
                yang bertentangan dengan praktik Good Corporate Governance
                (GCG).
              </p>

              <p>
                Landasan Kebijakan <i>Whistle Blowing System</i> (WBS) Sebagai
                Perusahaan BUMN Bertatakelola Perusahaan yang Baik (Good
                Corporate Governance) yang bebas dari Korupsi, Kolusi dan
                Nepotisme, diperlukan suatu kondisi yang bebas dari Benturan
                Kepentingan.
              </p>

              <p>
                Pelaksanaan <i>Whistle Blowing System</i> (WBS) di PT Kawasan
                Berikat Nusantara didasarkan pada Surat Keputusan Direksi Nomor
                318/SKD/DRT/DU.SPR.2/12/2022 tentang Perubahan atau pemuktahiran
                pedoman Sistem Pelaporan <i>Whistle Blowing System</i> (WBS) di
                PT Kawasan Berikat Nusantara. Penentuan kriteria dan unsur
                pengaduan ini bertujuan untuk memastikan kualitas pelaporan bisa
                dipertanggungjawabkan sehingga tidak merugikan pihak-pihak
                terkait.
              </p>

              <p>
                Pengaduan akan mudah ditindaklanjuti apabila memenuhi beberapa
                unsur antara lain:
              </p>
              <ol>
                <li>
                  Dapat memberikan informasi mengenai data diri,
                  sekurang-kurangnya memuat alamat rumah/kantor, nomor yang bisa
                  dihubungi yaitu telepon, handphone, email meskipun laporannya
                  tidak lengkap.
                </li>
                <li>
                  Dimungkinkan untuk merahasiakan identitas diri (anonim), namun
                  wajib memberikan informasi lengkap sekurang-kurangnya memuat
                  unsur-unsur yang harus ada dalam laporan WBS yakni,{" "}
                  <i>what, where, when, who, how.</i>
                </li>
              </ol>

              <p>
                Laporan pengaduan memberikan indikasi yang dapat
                dipertanggungjawabkan meliputi:
              </p>
              <ol type="a">
                <li>
                  Masalah yang diadukan (<i>What</i>) Pokok
                  Pengaduan/penyingkapan yang ingin diungkapkan dan jumlah
                  kerugian jika bisa ditentukan hanya untuk satu masalah saja
                  sehingga dapat terfokus.
                </li>
                <li>
                  Pihak yang terlibat (<i>Who</i>) Siapa yang seharusnya
                  bertanggung jawab atas kejadian tersebut termasuk saksi dan
                  siapa/pihak mana yang diuntungkan/dirugikan.
                </li>
                <li>
                  Lokasi Kejadian (<i>Where</i>) Dimana masalah tersebut
                  terjadi, dengan spesifik menyebutkan nama tempat atau fungsi
                  yang dimaksud.
                </li>
                <li>
                  Waktu Kejadian (<i>When</i>) Periode kejadian yang meyebutkan
                  tanggal, bulan dan tahun saat masalah tersebut terjadi.
                </li>
                <li>
                  Bagaimana terjadinya (<i>How</i>) Bagaimana kejadian itu bisa
                  terjadi dan apakah ada bukti atau tidak.
                </li>
              </ol>

              <p>
                Lingkup Pengaduan/Penyingkapan yang akan ditindaklanjuti oleh
                Tim <i>Whistle Blowing System</i> (WBS) PT KBN adalah tindakan
                yang dapat merugikan Perusahaan, meliputi sebagai berikut:
              </p>
              <ol>
                <li>
                  Penyimpangan dari peraturan dan perundangan yang berlaku.
                </li>
                <li>
                  Penyalahgunaan jabatan untuk kepentingan lain di luar
                  Perusahaan.
                </li>
                <li>Tindakan Korupsi, Kolusi dan Nepotisme.</li>
                <li>Pemerasan.</li>
                <li>Perbuatan Curang.</li>
                <li>Gratifikasi.</li>
              </ol>

              <h3>Perlindungan Bagi Pelapor</h3>
              <p>Perlindungan saksi pelapor berhak:</p>
              <ol>
                <li>
                  Memperoleh perlindungan atas kerahasiaan identitas pribadi,
                  jabatannya dan hak-hak sebagai pegawai lainnya yang berkenan
                  dengan kesaksiannya yang akan, sedang atau telah diberikannya.
                </li>
                <li>Memberikan keterangan tanpa tekanan dari pihak manapun.</li>
                <li>Bebas dari pertanyaan yang menjerat.</li>
                <li>
                  Mendapat informasi mengenai perkembangan kasus yang telah
                  dilaporkan.
                </li>
                <li>
                  Memperoleh penghargaan (reward) yang akan ditetapkan oleh
                  Direksi.
                </li>
              </ol>

              <h3>Hak saksi pelapor:</h3>
              <ol>
                <li>
                  Saksi pelapor dapat memberikan kesaksian tanpa harus hadir
                  langsung.
                </li>
                <li>
                  Saksi pelapor dapat memberikan kesaksiannya secara tertulis
                  disampaikan terhadap pejabat yang berwenang, dan membutuhkan
                  tanda tangan pada berita acara yang memuat tentang kesaksian
                  tersebut.
                </li>
                <li>
                  Saksi pelapor tidak dapat dikenakan sanksi atas Laporan
                  kesaksian yang akan, sedang dan atau telah diberikannya,
                  kecuali laporannya tidak benar.
                </li>
                <li>
                  Ketentuan ini tidak berlaku terhadap saksi pelapor yang
                  memberikan keterangan tidak dengan itikad baik.
                </li>
              </ol>

              <p>
                Pelapor dapat juga membuat pengaduan/pengungkapan dan
                mengirimkannya melalui sarana/media sebagai berikut:
              </p>
              <ul>
                <li>Telepon: (021) 4482-0909 ext. 1334</li>
                <li>
                  E-mail:{" "}
                  <i>
                    <u>pengaduan@kbn.co.id</u>
                  </i>
                </li>
              </ul>
            </div>
          </div>
        </>
      ) : null}
      
              <ViewCount count={data.views  ? data.views : 0} />
    </>
  );
}
