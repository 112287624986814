import whistleblowerMain from "../../assets/whistleblowerMain.png";
import ViewCount from "../../components/ViewCount.js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { server } from "../../config/server.js";
export default function Whistleblower() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [html,setHtml] = useState('')
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      wbs_user_email: document.getElementById("wbs_user_email").value,
      wbs_user_name: document.getElementById("wbs_user_name").value,
      wbs_user_phone: document.getElementById("wbs_user_phone").value,
      password: document.getElementById("password").value,
    };
    fetch(`${server}/api/v1/wbs/register`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.text())
      .then(async (res) => {
        console.log(res, "promox");
        setIsSubmitted(true)
        setHtml(res)
      })

      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <> <button
    onClick={(e) => {
      navigate(-1);
    }}
    class="btn mx-3 px-3 "
    style={{
      backgroundColor: "#005F3B",
      color: "white",
      borderRadius: "50rem",
      fontFamily: "Roboto",
      fontSize: 18,
      position: "absolute",
      left: "5%",
    }}
  >
   Back
  </button>
    {!isSubmitted ?   <div
        class=" pageText mw500 mx-auto h100 center-vertical justify-content-center"
        style={{ height: 500 }}
      >
        <div class="w100">
          <form onSubmit={(e) => handleSubmit(e)}>
            <label
              style={{ fontWeight: 500 }}
              className="text-left mb-2 pageText"
            >
              Email
            </label>
            <input
              style={{ borderRadius: 12, border: "1px solid #005F3B wb_input" }}
              required
              pattern="[^' ']+"
              type="email"
              id="wbs_user_email"
              // value={name}
              // onChange={(e) => {
              //   setTitle(e.target.value);
              // }}
              class="form-control py-2  mb-3 addForm "
              aria-describedby="passwordHelpBlock"
              placeholder="Email"
            />
            <label
              style={{ fontWeight: 500 }}
              className="text-left mb-2 pageText"
            >
              Username
            </label>
            <input
              style={{ borderRadius: 12, border: "1px solid #005F3B wb_input" }}
              required
              pattern="[^' ']+"
              id="wbs_user_name"
              // value={name}
              // onChange={(e) => {
              //   setTitle(e.target.value);
              // }}
              class="form-control py-2  mb-3 addForm "
              aria-describedby="passwordHelpBlock"
              placeholder="Username"
            />
            <label
              style={{ fontWeight: 500 }}
              className="text-left mb-2 pageText"
            >
              No Telp
            </label>
            <input
              style={{ borderRadius: 12, border: "1px solid #005F3B wb_input" }}
              required
              pattern="[^' ']+"
              id="wbs_user_phone"
              // value={name}
              // onChange={(e) => {
              //   setTitle(e.target.value);
              // }}
              class="form-control py-2  mb-3 addForm "
              aria-describedby="passwordHelpBlock"
              placeholder="No Telp"
            />
            <label
              style={{ fontWeight: 500 }}
              className="text-left mb-2 pageText"
            >
              Password
            </label>
            <input
              style={{ borderRadius: 12, border: "1px solid #005F3B wb_input" }}
              required
              pattern="[^' ']+"
              type="password"
              id="password"
              // value={name}
              // onChange={(e) => {
              //   setTitle(e.target.value);
              // }}
              class="form-control py-2  mb-3 addForm "
              aria-describedby="passwordHelpBlock"
              placeholder="Password"
            />
            <button
              // onClick={(e) => {
              //   console.log("infpo");
              //   e.preventDefault();
              //   localStorage.setItem("token", "master");
              //   navigate("/whistleblower");
              // }}
              class="w100 mt-3"
              type="submit"
              style={{
                height: 54,
                backgroundColor: "#005F3B",
                fontFamily: "Roboto",
                fontWeight: 600,
                color: "white",
                border: "0px solid transparent",
                borderRadius: 12,
              }}
            >
              Masuk
            </button>
          </form>
        </div>
      </div>:<div  dangerouslySetInnerHTML={{__html: html}}>

      </div>}
    </>
  );
}
