import logistikMain from "../../assets/logistikMain.png";
import prop1 from "../../assets/prop1.jpg";
import prop2 from "../../assets/prop2.jpg";
import prop3 from "../../assets/prop3.jpg";
import prop4 from "../../assets/prop4.jpg";
import ViewCount from "../../components/ViewCount.js";
import React, { useEffect, useState } from "react";
import "react-image-lightbox/style.css";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { server } from "../../config/server.js";
export default function RUPS() {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [data, setData] = useState([]);
  const [headerImg, setHeaderImg] = useState([]);
  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);
  const getData = () => {
    fetch(`${server}/api/v1/content/list/34`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);
        if (res.status) {
          setData(res.data[0] ? res.data[0] : {});
          setHeaderImg(res.data[0] ? res.data[0].content_header_link : "");
        } else {
          throw res;
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const images = [
    { src: prop1 },
    { src: prop2 },
    { src: prop3 },
    { src: prop4 },
  ];

  const handleLighbox = async (i) => {
    await console.log(i);
    await setSelectedImage(i);
    await console.log(selectedImage);
    setLightboxOpen(true);
  };

  return (
    <>
      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        slides={images}
        plugins={[Thumbnails]}
        index={selectedImage}
      />
      {/* <div
        class="pageMainImg"
        style={{ backgroundImage: `url(${logistikMain})` }}
      ></div> */}
      <img class="w100" style={{ borderRadius: 20 }} src={headerImg}></img>
      <div class="pageTitle">
        {data.content_title ? data.content_title : "Informasi RUPS"}
      </div>
      <div class="articleContainer pageText">
        {/* <div className="list image" style={{ textAlign: "justify" }}>
          {data.content_html ? (
            <div
              dangerouslySetInnerHTML={{ __html: data.content_html }}
            ></div>
          ) : null}
     
        </div> */}
        <div class="list image">
          <p>
            Rapat Umum Pemegang Saham / RUPS Perusahaan Memiliki Kewenangan Yang
            Tidak Diberikan Kepada Dewan Komisaris Maupun Direksi. Dalam RUPS,
            Pemegang Saham Berhak Mendapatkan Informasi Berkaitan Dengan
            Perusahaan Dari Dewan Komisaris Dan Atau Direksi Sepanjang Sejalan
            Dengan Kepentingan Perusahaan. Kewenangan RUPS Perusahaan Antara
            Lain:
          </p>

          <ul>
            <li>
              Melakukan Pengangkatan Dan Pemberhentian Direksi Dan Dewan
              Komisaris;
            </li>
            <li>
              Memberikan Keputusan Yang Diperlukan Untuk Menjaga Kepentingan
              Usaha Perusahaan Dalam Jangka Panjang Dan Jangka Pendek Sesuai
              Dengan Dengan Peraturan Perundang-Undangan Dan Anggaran Dasar
              Perusahaan;
            </li>
            <li>
              Memberikan Persetujuan Laporan Tahunan Termasuk Pengesahan Laporan
              Keuangan Serta Tugas Pengawasan Dewan Komisaris Sesuai Peraturan
              Perundang-Undangan Dan Anggaran Dasar Perusahaan;
            </li>
            <li>
              Mengambil Keputusan Melalui Proses Yang Terbuka Dan Adil Serta
              Dapat Dipertanggungjawabkan;
            </li>
            <li>
              Melaksanakan Tata Kelola Perusahaan Yang Baik Sesuai Dengan
              Wewenang Dan Tanggung Jawabnya.
            </li>
          </ul>

          <p>
            Mengacu Kepada Anggaran Dasar Perusahaan, Terdapat Dua Jenis RUPS,
            Yaitu:
          </p>

          <ul>
            <li>RUPS Tahunan (RUPST).</li>
            <p>
              Diselenggarakan Setiap Tahun, Selambatlambatnya Pada Bulan Juni
              Setelah Tutup Buku Perusahaan. Dalam RUPS Tahunan Dibahas Laporan
              Direksi Mengenai Perhitungan Tahunan, Laporan Tahunan Tentang
              Keadaan Dan Jalannya Perusahaan, Rencana Penggunaan Laba Dan
              Besarnya Dividen Yang Dibayarkan, Permintaan Penunjukkan Akuntan
              Publik Serta Hal Lainnya Demi Kepentingan Perusahaan.
            </p>

            <li>RUPS Luar Biasa (RUPSLB).</li>
            <p>
              Diselenggarakan Bila Dianggap Perlu Oleh Direksi Dan/Atau Dewan
              Komisaris Dan/ Atau Pemegang Saham. Pemanggilan Dan
              Penyelenggaraan RUPSLB Dilakukan Melalui Undangan Tertulis Dengan
              Menginformasikan Agenda Pembahasannya.
            </p>
          </ul>

          <p>
            Sepanjang Tahun 2018, Perusahaan Telah Melaksanakan 2 (Dua) Kali
            RUPS, Yaitu RUPS Laporan Pertanggungjawaban Keuangan Tahun Buku 2017
            Dan RUPS Pengesahan Rencana Kerja Dan Anggaran Perusahaan Untuk
            Tahun 2018.
          </p>

          <h2>Agenda, Keputusan dan Realisasi RUPS RKAP 2018</h2>
          <p>
            Kegiatan RUPS Dengan Agenda Pembahasan RKAP 2018 Dilaksanakan Pada
            Tanggal 5 Januari 2018 Dan Dihadiri Oleh Seluruh Pemegang Saham,
            Baik PT KBN (Persero), Kementerian BUMN Serta Pemerintah Provinsi
            DKI Jakarta Atau Setara 100% Pemegang Saham. Pelaksanaan RUPS RKAP
            2018 Tertuang Dalam Akta Notaris Nomor 04.-.
          </p>

          <p>Adapun Agenda RUPS Pembahasan RKAP 2018 Sebagai Berikut:</p>

          <h2>Agenda, Keputusan dan Realisasi RUPS 2018</h2>
          <p>
            Kegiatan RUPS Tahun 2018 Dengan Agenda Pengesahan Laporan Tahun Buku
            2017 Dilaksanakan Pada Tanggal 8 Mei 2018 Dan Dihadiri Oleh Seluruh
            Pemegang Saham, Baik PT KBN (Persero), Kementerian BUMN Serta
            Pemerintah Provinsi DKI Jakarta Atau Setara 100% Pemegang Saham.
            Pelaksanaan RUPS Dilaksanakan Di Kantor Kementerian BUMN, Jakarta.
          </p>

          <p>
            Adapun Agenda RUPS Pengesahan Laporan Tahun Buku 2017 Sebagai
            Berikut:
          </p>
        </div>
        <ViewCount count={data.views ? data.views : 0} />
      </div>
    </>
  );
}
