import logistikMain from "../../assets/logistikMain.png";
import akhlak from "../../assets/akhlak.png";
import ViewCount from "../../components/ViewCount.js";
import React, { useEffect, useState } from "react";
import { server } from "../../config/server.js";
export default function FAQ() {
  const [data, setData] = useState([]); 
  const [headerImg, setHeaderImg] = useState([]);
  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);
  const getData = () => {
    fetch(`${server}/api/v1/content/list/30`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);
        if (res.status) {
          setData(res.data[0] ? res.data[0] : {});
          setHeaderImg(res.data[0] ? res.data[0].
            content_header_link : '')
        } else {
          throw res
        }



      })


      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {/* <div
        class="pageMainImg"
        style={{ backgroundImage: `url(${logistikMain})` }}
      ></div> */}
      <img class="w100" style={{ borderRadius: 20 }} src={headerImg}></img>
      <div class="pageTitle">{data.content_title ? data.content_title : 'FAQ'}</div>
      <div class="articleContainer pageText">
      {/* <div className="list image" style={{ textAlign: "justify" }}>
          {data.content_html ? (
            <div
              dangerouslySetInnerHTML={{ __html: data.content_html }}
            ></div>
          ) : null}
  
        </div> */}
        <div class="list image">
          <p class="MsoNormal">
            <b>
              <span>
                MENGAPA PT KBN MENJADI LOKASI PILIHAN INVESTASI ANDA ?
              </span>
            </b>
          </p>
          <ul>
            <li>
              <span>
                PT KBN adalah salah satu BUMN yang bergerak dalam pengelolaan
                kawasan industri penyewaan property industri, pelayanan logistik
                terpadu dan lain2 sehingga bisa memberikan jaminan
                keberlangsungan bisnis dan pelayanan terintegrasi kepada
                pelanggan.
              </span>
            </li>
            <li>
              <span>
                Saham PT KBN dimiliki oleh pemerintah pusat dan Pemda DKI
                Jakarta sehingga PT KBN memiliki akses yang kuat kepada
                Pemerintah Pusat dan Pemda DKI Jakarta yang sangat penting untuk
                menjamin kelancaran bisnis pelanggan.
              </span>
            </li>
            <li>
              <span>
                Lokasi yang strategis, dekat dengan Pelabuhan International
                Tanjung Periuk, Jakarta International Container Terminal (JICT),
                dekat dengan Bandara Internasional Soekarno-Hatta, Bandara
                Internasional Halim Perdana Kusuma, Dekat dengan pusat
                pemerintahan Indonesia dan dekat dengan pusat komersial
                terkemuka Mall Kelap Gading Kelapa Gading dan Aeon Mall Cakung.
              </span>
            </li>
            <li>
              <span>Berlokasi di Ibukota Republik Indonesia</span>
            </li>
            <li>
              <span>
                Kawasan Industri PT KBN merupakan Objek Vital Nasional sehingga
                mendapatkan jaminan keamanan Pemerintah.
              </span>
            </li>
            <li>
              <span>
                Dilintasi Jalan Tol yang terhubung ke Pelabuhan Tanjung Periuk,
                Pusat Kota Jakarta dan Bandara
              </span>
            </li>
            <li>
              <span>
                Adanya Kantor Bea Cukai di dalam kawasan yang akan memudahkan
                pelayanan kepabeanan
              </span>
            </li>
            <li>
              <span>
                Pengeluaran IMB oleh PT KBN sehingga mampu mempercepat perijinan
                pembangunan
              </span>
            </li>
            <li>
              <span>
                Memiliki Rumah Sakit Umum Kelas C yang dilengkapi dengan dokter2
                spesialis dan laboratorium canggih dan dilengkapi fasilitas
                kesehatan di masing area yang siap memberikan pelayanan
                kesehatan prima kepala Para Pelanggan di Kawasan
              </span>
            </li>
            <li>
              <span>
                Memiliki fasilitas Pengolahan air bersih sendiri dan sambungan
                ke jaringan air bersih PDAM yang akan menjamin kelancaran supply
                air bersih
              </span>
            </li>
            <li>
              <span>
                Memiliki instalasi pengolahan sampah (incinerator) yang akan
                menjamin penanganan sampah kawasan lebih baik
              </span>
            </li>
            <li>
              <span>
                Memiliki instalasi pengolahan lombah cair (Waste Water
                Treatment)
              </span>
            </li>
            <li>
              <span>
                Memiliki sarana penanggulangan banjir (kolam tampung dan Pompa
                Air) untuk menjamin bebas banjir.
              </span>
            </li>
            <li>
              <span>
                Memiliki Supply listrik premiun dengan kapasitas tidak terbatas
                dari PLN
              </span>
            </li>
            <li>
              <span>Memiliki Jaringan Pipa Gas bekerjasama dengan PGN</span>
            </li>
            <li>
              <span>
                Memiliki jaringan fiber optik yang akan menjamin kualitas
                komunikasi
              </span>
            </li>
            <li>
              <span>
                Dilengkapi dengan sarana ibadah, perbankan, olah raga, mini
                market, restaurant dan kantin2 bersih dan sehat.
              </span>
            </li>
            <li>
              <span>
                Dilengkapi dgn jaringan CCTV, petugas keamanan 24 jam, pintu
                gerbang otomatis, inteligen keamanan, dan kantor polisi dalam
                kawasan yang akan menjamin kenyamanan dan keamanan para
                pelanggan
              </span>
            </li>
            <li>
              <span>
                Dilengkapi dengan unit pemadam kebakaran yang terkoneksi
                langsung dengan unit pemadam kebakaran Pemda yang akan menjamin
                kecepatan penanganan kebakaran
              </span>
            </li>
            <li>
              <span>Harga sewa yang kompetitif</span>
            </li>
          </ul>
          <p class="MsoNormal">
            <br />
          </p>
          <p class="MsoNormal">
            <b>
              <span>JENIS-JENIS INVESTASI YG BISA DILAKUKAN DI PT KBN ?</span>
            </b>
          </p>
          <ul>
            <li>
              <span>
                Penyewaan lahan industri untuk membangun pabrik dan gudang di
                area berikat maupun non berikat.
              </span>
            </li>
            <li>
              <span>
                Penyewaan bangunan pabrik dan atau gudang di area berikat maupun
                non berikat
              </span>
            </li>
            <li>
              <span>
                Kerjasama sebagai Pengusaha Dalam Pusat Logistik Berikat (PDPLB)
              </span>
            </li>
            <li>
              <span>Penyewaan bangunan komersial dan perkantoran</span>
            </li>
            <li>
              <span>
                Penyewaan lahan di area non berikat untuk bisnis lain yang
                diijinkan oleh KBN
              </span>
            </li>
            <li>
              <span>
                Kerjasama bisnis lain yang saling menguntungkan kedua belah
                pihak
              </span>
            </li>
            <li>
              <br />
            </li>
          </ul>
          <p class="MsoNormal">
            <b>
              <span>SIAPA SAJA YANG BISA BERINVESTASI DI PT KBN ?</span>
            </b>
          </p>
          <p class="MsoNormal">
            <span>
              Pengusaha dalam negeri dan Pengusahaan luar negeri yang memenuhi
              syarat untuk menjalankan bisnis sesuai perundang-undangan yang
              berlaku di Republik Indonesia
            </span>
          </p>
          <p class="MsoNormal">
            <br />
          </p>
          <p class="MsoNormal" s>
            <b>
              <span>BAGAIMANA CARA BERINVESTASI DI PT KBN ?</span>
            </b>
          </p>
          <ul>
            <li>
              <span>
                Mengajukan surat minat berinvestasi di Indonesia (online letter
                of intent)
              </span>
              <br />
            </li>
            <li>
              <span>
                PT KBN mengevaluasi surat minat tersebut dan mengeluarkan
                balasan atas surat minat tersebut
              </span>
            </li>
            <li>
              <span>
                Apabila kedua belah pihak sepakat maka ditindaklanjuti dengan
                perjanjian kerjasama
              </span>
            </li>
            <li>
              <span>
                Sebelum penandatangan perjanjian, investor harus melakukan
                pembayaran sesuai kesepakatan
              </span>
            </li>
          </ul>
          <p class="MsoNormal">
            <br />
          </p>
          <p class="MsoNormal">
            <b>
              <span>KAPAN BISA MENGHUBUNGI PT KBN ?</span>
            </b>
          </p>
          <p class="MsoNormal">
            <span>Kami dapat dihubungi melalui :</span>
          </p>
          <p class="MsoNormal">
            <span>Website :&nbsp; www.kbn.co.id,</span>
          </p>
          <p class="MsoNormal">
            <span>Email&nbsp; &nbsp; &nbsp;:&nbsp; marketing@kbn.co.id,</span>
          </p>
          <p class="MsoNormal">
            <span>
              Media Sosial PT KBN : @kbnpersero (Instagram, Facebook, Twitter)
            </span>
          </p>
          <p class="MsoNormal">
            <span>
              Pelayanan langsung melalui contact center no 02144820909 atau
              marketing office setiap Hari Kerja dr jam 08:00 - 17:00
            </span>
          </p>
        </div>

     
        <ViewCount count={data.views ? data.views : 0} />
      </div>
    </>
  );
}
