import send from "../../assets/send.png";
import ViewCount from "../../components/ViewCount.js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WBSMenu from "./Menu.js";
import { server } from "../../config/server.js";
import Swal from "sweetalert2";
export default function Whistleblower() {
  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);
  const [jenis, setJenis] = useState([]);
  const [headerImg, setHeaderImg] = useState([]);
  const [data, setData] = useState([]);
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const getData = () => {
    fetch(`${server}/api/v1/content/list/27`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      // body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);
        if (res.status) {
          console.log(res,'kiu');
          
          setData(res.data[0] ? res.data[0] : {});
          setHeaderImg(res.data[0] ? res.data[0].content_header_link : "");
        } else {
          throw res;
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
 
  // const formData = await new FormData();
  // await formData.append("image_file", imageToUpload);
  // // await formData.append("Thumbnail", thumbnail);

  // await formData.append(
  //   "title",
  //   document.getElementById("title_create_sliders").value
  // );
  const handleSubmit = async () => {
    // const data = {
    //   wbs_user_email: document.getElementById("email_login").value,
    //   password: document.getElementById("password_login").value,
    // };
    console.log(document.getElementById("image_file").files[0]);

    const formData = await new FormData();
    // await formData.append("image_file", imageToUpload);
    // await formData.append("Thumbnail", thumbnail);

    await formData.append(
      "objection_description",
      document.getElementById("objection_description").value
    );


    await formData.append(
      "image_file",
      document.getElementById("image_file").files[0]
    );

    console.log("masukxa");
    fetch(`${server}/api/v1/objection/objection`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      body: formData,
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res, "promosx");
        const Toast = Swal.mixin({
          // toast: true,
          // position: "top-end",
          showConfirmButton: true,
          // backdrop: false,
          // timer: 3000,
          // animation:false,
          backdrop: `transparent`,
          // timerProgressBar: true,
          allowOutsideClick: false,
          confirmButtonColor: `#005f3b`,
          confirmButtonFontFamily: "Roboto",
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        if (!res.status) {
          Toast.fire({
            // icon: "error",

            title: res.data.message,
            text: res.data.desc,
          });
        } else if (res.status == true) {
          Toast.fire({
            // icon: "error",

            title: "Success",
            text: "Laporan berhasil dibuat",
          }).then((isConfirm) => {
            navigate("/suara-pelanggan/permohonan-informasi/status");
          });
        }
      })

      .catch((err) => {
        console.log(err);
      });
      console.log('asd');
      
  };
  const imagePreview = async (file) => {
    const reader = new FileReader();
    reader.onload = function () {
      console.log(reader.result); // Base64 string
    };
    reader.readAsDataURL(file);
    return await reader.readAsDataURL(file);
  };
  return (
    <>
      {/* <div
        class="pageMainImg"
        style={{ backgroundImage: `url(${whistleblowerMain})` }}
      ></div> */}
      {/* <div class="w100 text-center">
        <button
          class="btn mx-3 px-5 "
          style={{
            backgroundColor: "#005F3B",
            color: "white",
            borderRadius: "50rem",
            fontFamily: "Roboto",
            fontSize: 18,
          }}
        >
          Buat Pengaduan
        </button>
        <button
          class="btn mx-3 px-5 "
          style={{
            backgroundColor: "#EF582C",
            color: "white",
            borderRadius: "50rem",
            fontFamily: "Roboto",
            fontSize: 18,
          }}
        >
          Register
        </button>
        <button
          class="btn mx-3 px-5 greenText"
          style={{
            borderRadius: "50rem",
            fontFamily: "Roboto",
            fontSize: 18,
            border: "1px solid #005F3B",
          }}
        >
          Login
        </button>
      </div> */}{" "}
        <img
        class="w100"
        className="w100 mb-5"
        style={{ borderRadius: 20 }}
        src={headerImg}
      ></img>
      <WBSMenu />
      <div class="pageTitle">Form Permohonan Keberatan Informasi</div>
      <div
        class=" pageText  mx-auto h100 w100  justify-content-center"
        style={{}}
      >
        <form
          onSubmit={(e) => {
            console.log("infpo");
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div class="w100">
            {/* <label style={{}} className="text-left mb-2 pageText">
            Nama Pelapor
          </label>
          <input
            style={{ border: "1px solid #005F3B wb_input" }}
            required
            pattern="[^' ']+"
            type="text"
            id="client_code"
            // value={name}
            // onChange={(e) => {
            //   setTitle(e.target.value);
            // }}
            class="form-control py-2  mb-3 addForm br10"
            aria-describedby="passwordHelpBlock"
            placeholder="Nama Pelapor"
          /> */}
            <div
              class="flex w100 "
              style={{ justifyContent: "space-between", gap: "25px" }}
            >
              <div class="w100">
                <label style={{}} className="text-left mb-2 pageText">
                  Deskripsi Permohonan
                </label>
                <input
                  style={{
                    border: "1px solid #005F3B wb_input",
                  }}
                  type="text"
                  id="objection_description"
                  // value={attn}
                  // onChange={(e) => {
                  //   setTitle(e.target.value);
                  // }}
                  required
                  class="form-control py-2  mb-3 addForm br10"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Deskripsi Permohonan"
                />
              </div>
            </div>
            {/* <div
              class="flex w100 "
              style={{ justifyContent: "space-between", gap: "25px" }}
            >
              <div class="w100">
                <label style={{}} className="text-left mb-2 pageText">
                  Jalur Penerimaan Informasi
                </label>
                <input
                  style={{
                    border: "1px solid #005F3B wb_input",
                  }}
                  type="text"
                  required
                  id="information_request_deliver"
                  // value={Title}
                  // onChange={(e) => {
                  //   setTitle(e.target.value);
                  // }}
                  class="form-control py-2  mb-3 addForm br10"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Jalur Penerimaan Informasi"
                />
              </div>
            </div> */}
            <label style={{}} className="text-left mb-2 pageText">
              Foto Identitas
            </label>
            <input
              style={{ border: "1px solid #005F3B wb_input" }}
              pattern="[^' ']+"
              type="file"
              id="image_file"
              required
              multiple="multiple"
              // value={name}
              onChange={(e) => {
                console.log(e.target.files[0]);

                setImage(e.target.files[0]);
              }}
              class="form-control   mb-3 addForm br10"
              aria-describedby="passwordHelpBlock"
              placeholder="lampiran Bukti Permulaan"
            />
            {/* <label style={{}} className="text-left mb-2 pageText">
              Alamat Tempat Tugas
            </label> */}
            <div
              style={{ border: "1px solid #005F3B wb_input", height: 250 }}
              pattern="[^' ']+"
              type="text"
              id="reported_place"
              // value={name}
              // onChange={(e) => {
              //   setTitle(e.target.value);
              // }}
              class="form-control py-2  mb-3 addForm br10 text-center"
              aria-describedby="passwordHelpBlock"
            >
              {image ? (
                <>
                  <img
                    style={{ height: "100%", margin: "auto" }}
                    src={URL.createObjectURL(image)}
                  ></img>
                </>
              ) : (
                <>{null}</>
              )}
            </div>
            <button
              onClick={(e) => {
                // console.log("infpo");
                // e.preventDefault();
                // navigate("/whistleblower");
              }}
              class="  px-4 flex center-vertical"
              style={{
                height: 54,
                maxWidth: 354,
                backgroundColor: "#005F3B",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: 20,
                color: "white",
                border: "0px solid transparent",
                borderRadius: 10,
                gap: 70,
                marginTop: "2rem",
              }}
            >
              <div>Submit Laporan</div>
              <div>
                <img src={send} style={{ width: "1.5rem" }}></img>
              </div>
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
