import React, { useEffect, useState } from "react";
import { server } from "../../config/server.js";
import ViewCount from "../../components/ViewCount.js";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import logistikMain from "../../assets/logistikMain.png";
import "./Majalah.css"; // Assuming you have a CSS file for styling

export default function Majalah() {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [album, setAlbum] = useState([]);
  const [header, setHeader] = useState({});
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [headerResponse, albumResponse] = await Promise.all([
        fetch(`${server}/api/v1/content/list/43`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }),
        fetch(`${server}/api/v1/content/detail/3/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }),
      ]);

      const [headerResult, albumResult] = await Promise.all([
        headerResponse.json(),
        albumResponse.json(),
      ]);

      if (headerResult.status) {
        setHeader(headerResult.data[0] || {});
      } else {
        throw new Error("Failed to fetch header data");
      }

      if (albumResult.status) {
        const formattedAlbum = formatAlbumData(albumResult.data);
        setAlbum(formattedAlbum);
      } else {
        throw new Error("Failed to fetch album data");
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    }
  };

  const formatAlbumData = (data) => {
    return data.map((album) => ({
      ...album,
      content_files: album.content_files.map((file) => ({
        ...file,
        src: file.public_link,
      })),
      thumbnail: album.content_thumbnail_link?.[0]?.public_link || "", // Ensure correct thumbnail assignment
    }));
  };

  const handleAlbumClick = (photos, title) => {
    galleryModal(photos, title);
  };

  const galleryModal = (photos, title) => {
    MySwal.fire({
      html: (
        <div>
          <div className="modalTitle">{title}</div>
          <div className="popupFotoContainer">
            {photos.map((img, i) => (
              <img
                key={i}
                onClick={() => {
                  setLightboxOpen(true);
                  setSelectedImage(i);
                }}
                className="galleryImg"
                src={img.public_link}
                alt={`Photo ${i + 1}`}
              />
            ))}
          </div>
          <button className="btn" onClick={() => MySwal.close()}>
            Back
          </button>
        </div>
      ),
      width: "fit-content",
      height: "fit-content",
      customClass: "galleryModal",
      allowOutsideClick: false,
      showClass: {
        backdrop: "swal2-noanimation",
        popup: "animated fadeInDown faster",
        icon: "animated heartBeat delay-1s",
      },
      showConfirmButton: false,
      heightAuto: false,
    });
  };

  const backgroundImage = header.content_header_link
    ? header.content_header_link.replace(/ /g, "%20")
    : null;
  const contentTitle = header.content_title || "";
  const contentViews = header.views || "0";

  return (
    <>
      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        slides={album[selectedImage]?.content_files || []}
        plugins={[Thumbnails]}
        index={selectedImage}
      />
      {/* {JSON.stringify(album)} */}
      <img class="w100" style={{ borderRadius: 20 }} src={backgroundImage}></img>
      <div className="pageTitle">{contentTitle}</div>
      <div className="articleContainer">
        <div className="magazineGrid">
          {album.map((photos, index) => (
            <div
              key={index}
              className="magazineItem"
              onClick={() => window.open(photos.content_files.length > 0 ? `${photos.content_files[0].public_link}`:'', '_blank')}
            >
              <div
                className="albumThumbnail"
                style={{
                  backgroundImage: `url("${photos.content_thumbnail_link}")`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  cursor: "pointer",
                }}
              >
                <div className="magazineItemTitle">{photos.content_title}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ViewCount count={contentViews} />
    </>
  );
}
