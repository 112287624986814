import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { server } from "../config/server.js";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
export default function PageIndex() {
    const navigate = useNavigate();
  useEffect(() => {
    console.log(localStorage.getItem("token"));
    
    if(localStorage.getItem("token")){
        console.log('cekgam');
        
        fetch(`${server}/api/v1/information_request/information_request`, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            // mode: "cors", // no-cors, *cors, same-origin
            // body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
            .then((res) => res.json())
            .then(async (res) => {
              console.log(res,'hook');
              
              if (!res.status && (res.data?.message == "token expired" || res.data?.message == "invalid access token")) {
                const Toast = Swal.mixin({
                  // toast: true,
                  // position: "top-end",
                  showConfirmButton: true,
                  // backdrop: false,
                  // timer: 3000,
                  // animation:false,
                  backdrop: `transparent`,
                  // timerProgressBar: true,
                  allowOutsideClick: false,
                  confirmButtonColor: `#005f3b`,
                  confirmButtonFontFamily: "Roboto",
                  didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                  },
                });
                Toast.fire({
                  // icon: "error",
      
                  title: "Session Expired",
                  text: "Plese re-log to your account",
                }).then((result) => {
                  if (result.isConfirmed) {
                      localStorage.removeItem('token');
                    console.log("a1");
                    navigate('/app/login')
                  }
                });
              } else if (res.status == true) {
                return;
              }
            })
      
            .catch((err) => {
              console.log(err);
            });
    }
   
    window.scrollTo(0, 0);
  }, []);
  return <Outlet />;
}
