// import whistleblowerMain from "../../assets/whistleblowerMain.png";
// import ViewCount from "../../components/ViewCount.js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { server } from "../../config/server.js";
import Swal from "sweetalert2";
export default function Whistleblower() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    const data = {
      wbs_user_email: document.getElementById("email_login").value,
      password: document.getElementById("password_login").value,
    };
    console.log(data, `${server}/api/v1/wbs/login`);
    e.preventDefault();  
    
    fetch(`${server}/api/v1/wbs/login`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      // mode: "cors", // no-cors, *cors, same-origin
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        // access_token: localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then(async (res) => {

        if (!res.status) {
          const Toast = Swal.mixin({
            // toast: true,
            // position: "top-end",
            showConfirmButton: true,
            // backdrop: false,
            // timer: 3000,
            // animation:false,
            backdrop:`transparent`,
            // timerProgressBar: true,
            allowOutsideClick: false,
            confirmButtonColor:`#005f3b`,
            confirmButtonFontFamily:'Roboto',
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            // icon: "error",

            title: "Error", text: "Username atau password salah",
          });
        }else if(res.status == true){
          localStorage.setItem("token",res.data.accessToken)
          navigate(-1)
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
    <button
    onClick={(e) => {
      navigate(-1);
    }}
    class="btn mx-3 px-3 "
    style={{
      backgroundColor: "#005F3B",
      color: "white",
      borderRadius: "50rem",
      fontFamily: "Roboto",
      fontSize: 18,
      position: "absolute",
      left: "5%",
    }}
  >
   Back
  </button>
      <div
        class=" pageText mw500 mx-auto h100 center-vertical justify-content-center"
        style={{ height: 500 }}
      >
        <div class="w100">
          <form onSubmit={(e) => handleSubmit(e)}>
            <label
              style={{ fontWeight: 500 }}
              className="text-left mb-2 pageText"
            >
              Email
            </label>
            <input
              style={{ borderRadius: 12, border: "1px solid #005F3B wb_input" }}
              required
              pattern="[^' ']+"
              type="email"
              id="email_login"
              // value={name}
              // onChange={(e) => {
              //   setTitle(e.target.value);
              // }}
              class="form-control py-2  mb-3 addForm "
              aria-describedby="passwordHelpBlock"
              placeholder="Email"
            />
            <label
              style={{ fontWeight: 500 }}
              className="text-left mb-2 pageText"
            >
              Password
            </label>
            <input
              style={{ borderRadius: 12, border: "1px solid #005F3B wb_input" }}
              required
              pattern="[^' ']+"
              type="password"
              id="password_login"
              // value={name}
              // onChange={(e) => {
              //   setTitle(e.target.value);
              // }}
              class="form-control py-2  mb-3 addForm "
              aria-describedby="passwordHelpBlock"
              placeholder="Password"
            />
            <button
              // onClick={(e) => {
              //   console.log("infpo");
              //   e.preventDefault();
              //   localStorage.setItem("token", "master");
              //   navigate("/whistleblower");
              // }}
              class="w100 mt-3"
              type="submit"
              style={{
                height: 54,
                backgroundColor: "#005F3B",
                fontFamily: "Roboto",
                fontWeight: 600,
                color: "white",
                border: "0px solid transparent",
                borderRadius: 12,
              }}
            >
              Masuk
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
