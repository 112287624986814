import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import "react-image-lightbox/style.css";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { server } from "../../config/server.js";
import ViewCount from "../../components/ViewCount.js";

export default function BeritaById() {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [news, setNews] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  const MySwal = withReactContent(Swal);

  // Fetch news data
  const getNews = async () => {
    try {
      const res = await fetch(`${server}/api/v1/news/view?news_id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await res.json();
      if (data.status) setNews(data.data[0]);
      else throw new Error("Failed to fetch news");
    } catch (err) {
      console.error(err);
    }
  };

  // Trigger Lightbox
  const handleLightbox = (index) => {
    setSelectedImage(index);
    setLightboxOpen(true);
  };

  useEffect(() => {
    getNews();
    window.scrollTo(0, 0);
  }, [id]);


  return (
    <>
      {/* Lightbox for image viewing */}
      <Lightbox
        open={lightboxOpen}
        close={() => setLightboxOpen(false)}
        slides={news.images || []}
        plugins={[Thumbnails]}
        index={selectedImage}
      />

      {/* Page Content */}
      {news.images?.[0] && (
        <div
          className="pageMainImg"
          style={{ backgroundImage: `url('${news.images[0].public_link}')` }}
        ></div>
      )}

      <div className="pageTitle" style={{ fontSize: 34, textAlign: "center" }}>
        {news.news_title}
      </div>

      <div className="articleContainer pageText">
        <p style={{ fontSize: 18 }}>
          {moment(news.news_entry_date).locale("id").format("DD MMM YYYY")}
        </p>
        <div
          className="w100"
          dangerouslySetInnerHTML={{ __html: news.content }}
        ></div>
        <ViewCount count={news.views || 0} />
      </div>
    </>
  );
}
