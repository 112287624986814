import React, { useEffect, useState } from "react";
import { server } from "../../config/server.js";
import ViewCount from "../../components/ViewCount.js";
import logistikMain from "../../assets/logistikMain.png";

export default function PusatBisnis() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(`${server}/api/v1/content/list/24`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const result = await response.json();
        setData(result.data);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
    window.scrollTo(0, 0);
  }, []);

  const backgroundImage =
    data.length > 0 ? data[0].content_header_link.replace(/ /g, "%20") : null;
  const contentHTML =
    data.length > 0 && data[0].content_html
      ? data[0].content_html
      : "<p>No Content Available";

  return (
    <>
      <img
        class="w100"
        style={{ borderRadius: 20 }}
        src={backgroundImage}
      ></img>
      <a>
        <div className="pageTitle">
          {data.length > 0 ? data[0].content_title : "Loading..."}
        </div>
      </a>

      <div className="articleContainer pageText">
        <div className="list image" style={{ textAlign: "justify" }}>
          <a>
            <div dangerouslySetInnerHTML={{ __html: `<a>${contentHTML}</a>` }}></div>
          </a>
        </div>
        <ViewCount count={data.length > 0 ? data[0].views : 0} />
      </div>
    </>
  );
}
